import {GetStaticProps} from "next";
import {config} from "../../config-merchant";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import HeaderAppyShop, {getComponentStaticProps, HeaderProps} from "../../components/header/headerAppyShop";
import {appyShopConfig} from "../../config";
import Meta from "../../components/meta/meta";
import FooterAppyShop from "../../components/footer/footerAppyShop";
import React, {useEffect, useState} from "react";
import {useTranslation} from "next-i18next";
import styles from "./login.module.scss";
import {Field, Form, Formik} from "formik";
import Loader from "../../components/loader/loader";
import {toast} from "react-toastify";
import * as yup from "yup";
import {login} from "../../services/customer-services";
import 'react-toastify/dist/ReactToastify.css';
import Link from "next/link";
import {useDispatch, useSelector} from "react-redux";
import {loginCustomer} from "../../store/action";
import {Customer, ShopState} from "../../interfaces/store-interfaces";
import {useRouter} from 'next/router'

function Login(props: {
    headerProps: HeaderProps,
}) {

    const {t} = useTranslation('common');
    const {i18n} = useTranslation('common')

    const [isSubmitClicked, setSubmitClicked] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const router = useRouter();
    const dispatch = useDispatch();
    const customer: Customer = useSelector((state: ShopState) => state.customer);

    const options = {
        autoClose: 3000,
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const loginResponse = await login({email: values.email, password: values.password});
            dispatch(loginCustomer(loginResponse.data));
            setLoading(false);
        } catch (e) {
            setLoading(false);
            toast(t('LOGIN_BAD_REQUEST'), options);
        }
    }

    const schema = yup.object({
        email: yup.string().required(t('ORDER_MISSING_MAIL')).email(t('ORDER_ERROR_MAIL')),
        password: yup.string().required(t('LOGIN_PASSWORD_REQUIRED')),
    });

    useEffect(() => {
        if (router.isReady && customer !== undefined && customer !== null) {
            if (router.query.redirect !== undefined) {
                router.push("" + router.query.redirect);
            } else {
                router.push("my-account");
            }
        }
    }, [customer]);

    return (
        <>
            <Meta
                title={t('LOGIN_PAGE_TITLE')}
                description={config.shopSlogan[i18n.language]}
                image={config.merchantDomain + config.defaultMetaImage}
                metaRobots={true}
            />
            <HeaderAppyShop headerProps={props.headerProps} activepage={'login'}/>
            <main>
                <div className={`${styles['login']}`}>
                    <div className={styles["login-text"]}>
                        <div>
                            ⚠️Suite à une migration complète du site le 23 février 2022, tous les comptes créés avant cette
                            date n'ont pas pu être conservés. Si c'est votre cas vous devrez donc en créer un nouveau.
                            <br/>Toutes nos excuses pour le désagrément.
                            <br/>
                            <br/>
                        </div>
                        <h2>{t('LOGIN_TITLE')}</h2>
                        <div className={styles["login-form"]}>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                }}
                                onSubmit={handleSubmit}
                                validationSchema={schema}
                            >
                                {({errors}) => (
                                    <Form>
                                        {(isSubmitClicked && errors.email) &&
                                            <div className={styles['error-form']}>{errors.email}</div>}
                                        <Field
                                            name="email"
                                            type="text"
                                            placeholder={t('LOGIN_EMAIL')}
                                        />
                                        {(isSubmitClicked && errors.password) &&
                                            <div className={styles['error-form']}>{errors.password}</div>}
                                        <Field
                                            name="password"
                                            type="password"
                                            placeholder={t('LOGIN_PASSWORD')}
                                        />
                                        <Link href={"/login/forgot-password"}>
                                            <div className={styles["forgot-password"]}>
                                                {t("LOGIN_FORGOT_PASSWORD")}
                                            </div>
                                        </Link>
                                        <div className={styles["loader-place"]}>
                                            {isLoading && <Loader/>}
                                        </div>
                                        <button
                                            type="submit"
                                            onClick={() => setSubmitClicked(true)}>
                                            {t('LOGIN_SUBMIT')}
                                        </button>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className={`${styles['no-account']}`}>
                            <h2>{t('LOGIN_NO_ACCOUNT_TITLE')}</h2>
                            <Link
                                href={
                                    (router.isReady && router.query.redirect !== undefined) ?
                                        "/sign-up?redirect=" + router.query.redirect : "/sign-up"}>
                                <button>
                                    {t('LOGIN_NO_ACCOUNT_BUTTON')}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </main>
            <FooterAppyShop/>
        </>
    );
}

export const getStaticProps: GetStaticProps = async ({locale}) => {
    if (config.menu.some(item => item.path === 'catalog') || config.footerLink.some(item => item.url === '/catalog')) {
        return {
            props: {
                ...await serverSideTranslations(locale, ['common']),
                headerProps: await getComponentStaticProps(locale)
            },
            revalidate: appyShopConfig.revalidateData
        }
    } else {
        return {
            notFound: true
        }
    }
}

export default Login;
